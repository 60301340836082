import React, { useState, useEffect, useCallback } from "react";

import {
  Grid,
  GridColumn,
  GridToolbar,
  getSelectedState,
} from "@progress/kendo-react-grid";

import { DropDownButton } from "@progress/kendo-react-buttons";
import { process, aggregateBy } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { getter } from "@progress/kendo-react-common";
import GridExport from "../../export/GridExport";

export default function SharedMainSelectableGrid(props) {
  const { styleOverride, pageSize = 50 } = props;

  const defaultButtonStyleClasses =
    "k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary";

  const INITIAL_MAIN_GRID_DATA_STATE = { skip: 0, take: pageSize };
  const SELECTED_FIELD = "SelectedId";
  const DATA_ITEM_KEY = props.dataItemKey;
  const idGetter = getter(DATA_ITEM_KEY);

  const gridButtons = props.gridButtons;

  const [mainGridData, setMainGridData] = useState(
    props.data ? props.data : []
  );
  const [mainGridDataState, setMainGridDataState] = useState(
    INITIAL_MAIN_GRID_DATA_STATE
  );
  const [mainGridResultState, setMainGridResultState] = useState(mainGridData);
  const [selectedMainGridState, setSelectedMainGridState] = useState({});

  const [exportData, setExportData] = useState([]);
  const [exportColumns, setExportColumns] = useState([]);

  const exportReportOptions = {
    hasGenerateReportOption: props.hasGenerateReportOption,
    generateReportButtonTitle: props.generateReportButtonTitle,
    generateReportForSelectablesCallback:
      props.generateReportForSelectablesCallback,
  };

  const _grid = React.useRef();
  const _mainExport = React.useRef(null);

  const onMainGridDataStateChange = (event) => {
    setMainGridDataState(event.dataState);
    let processedData = process(
      mainGridData.map((item) => ({
        ...item,
        [SELECTED_FIELD]: selectedMainGridState[idGetter(item)],
      })),
      event.dataState
    );

    setMainGridResultState(processedData);
  };

  //NOTE: When a filter is applied, this useEffect hook will kick off to update the aggregates
  useEffect(() => {
    let aggregates = {};
    let filterSettings = {};

    filterSettings.filter = mainGridDataState.filter;
    let filteredMainGridData = process(mainGridData, filterSettings);

    if (filteredMainGridData.data && props.aggregateColumnSettings) {
      aggregates = aggregateBy(
        filteredMainGridData.data,
        props.aggregateColumnSettings
      );
    }

    props.sendAggregatesParentCallback(aggregates);

    setExportData(filteredMainGridData.data);

    if (_grid.current && _grid.current.columns)
      setExportColumns(_grid.current.columns);
  }, [mainGridData, mainGridDataState.filter]);

  //NOTE: This is all for handling the selectable function
  useEffect(() => {
    let processedData = process(
      mainGridData.map((item) => ({
        ...item,
        [SELECTED_FIELD]: selectedMainGridState[idGetter(item)],
      })),
      mainGridDataState
    );

    setMainGridResultState(processedData);
    
  }, [selectedMainGridState]);

  const onSelectionChange = useCallback(
    (event) => {
      if (event.startColIndex == 0) {
        const newSelectedState = getSelectedState({
          event,
          selectedState: selectedMainGridState,
          dataItemKey: DATA_ITEM_KEY,
        });

        setSelectedMainGridState(newSelectedState);
      }
    },
    [selectedMainGridState]
  );

  const GetSelectedGridDataKeys = () => {
    var selectableKeys = Object.keys(selectedMainGridState);

    var selectedKeys = selectableKeys.filter(function (key) {
      return selectedMainGridState[key] === true;
    });

    return selectedKeys;
  };

  const ShouldDisableToolbarButton = (
    shouldDisableWhenNoRecordSelectedOption
  ) => {
    //if shouldDisableWhenNoRecordSelectedOption is not true, then no need to test for anything and njust return false
    if (shouldDisableWhenNoRecordSelectedOption !== true) return false;

    //now we need to run some tests to see if the button should be disabled
    //if any records are selected, then the button should be enabled

    var selectedKeys = GetSelectedGridDataKeys();

    //if there are no selected keys, then the button should be disabled
    if (selectedKeys.length === 0) return true;

    return false;
  };

  //NOTE: This runs when the data gets updated (either on initial page load or new data fetch)
  useEffect(() => {
    setSelectedMainGridState({});
    setMainGridData(props.data);
    let processedData = process(props.data, INITIAL_MAIN_GRID_DATA_STATE);
    setMainGridResultState(processedData);
    setMainGridDataState(INITIAL_MAIN_GRID_DATA_STATE);
  }, [props.data]);

  const onHeaderSelectionChange = useCallback(
    (event) => {
      const checkboxElement = event.syntheticEvent.target;
      const checked = checkboxElement.checked;
      const newSelectedState = {};

      if (mainGridData.length > 0) {
        mainGridData.forEach((item) => {
          newSelectedState[idGetter(item)] = checked;
        });
      }
      setSelectedMainGridState(newSelectedState);

    },
    [selectedMainGridState]
  );

  const ExportReportsDropdownItems = [
    { text: "Excel", id: 1 },
    { text: "CSV", id: 2 },
  ];

  const onItemClick = (event) => {
    exportReportOptions.generateReportForSelectablesCallback(
      selectedMainGridState,
      event.item.text
    );
  };

  return (
    <React.Fragment>
      <ExcelExport ref={_mainExport}>
        <GridExport
          exportData={exportData}
          exportDataColumns={exportColumns}
          exportFileNamePrefix={props.exportFileNamePrefix}
        />

        <Grid
          style={styleOverride ? styleOverride : { height: "700px" }}
          ref={_grid}
          data={mainGridResultState}
          filterable={true}
          sortable={true}
          groupable={true}
          pageable={true}
          dataItemKey={DATA_ITEM_KEY}
          selectedField={SELECTED_FIELD}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: "multiple",
          }}
          onDataStateChange={onMainGridDataStateChange}
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
          {...mainGridDataState}
        >
          <GridToolbar>
            {gridButtons &&
              gridButtons.map((thisButton) => (
                <button
                  key={thisButton.text.replace(/\s+/g, "").trim().toLowerCase()}
                  title={thisButton.text}
                  className={
                    thisButton.styleOverride || defaultButtonStyleClasses
                  }
                  disabled={ShouldDisableToolbarButton(
                    thisButton.disableWhenNoRecordSelected
                  )}
                  onClick={() => {
                    thisButton.callback(GetSelectedGridDataKeys());
                  }}
                >
                  {thisButton.text}
                </button>
              ))}

            {exportReportOptions.hasGenerateReportOption && (
              <DropDownButton
                text={exportReportOptions.generateReportButtonTitle}
                textField="text"
                items={ExportReportsDropdownItems}
                onItemClick={onItemClick}
                themeColor={"primary"}
              />
            )}
          </GridToolbar>
          <GridColumn
            field={SELECTED_FIELD}
            width={"50px"}
            // headerCell={myCustomHeaderSelection}
            // width={"100px"}
            filterable={false}
            headerSelectionValue={
              mainGridData.findIndex(
                (item) => !selectedMainGridState[idGetter(item)]
              ) === -1
            }
          />

          {props.children}
        </Grid>
      </ExcelExport>
    </React.Fragment>
  );
}

//This method takes in a currentArray, buttonText, and buttonCallback and returns a new array with the button added to the end of the currentArray
export function AddGridButton(
  currentArray,
  buttonText,
  buttonCallback,
  disableWhenNoRecordSelected = true,
  styleOverride = null
) {
  let newArrayItem = {
    text: buttonText,
    callback: buttonCallback,
    disableWhenNoRecordSelected: disableWhenNoRecordSelected,
    styleOverride: styleOverride,
  };

  let newArray = [...currentArray, newArrayItem];

  return newArray;
}
