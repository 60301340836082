import React from 'react'
import { Tab, Tabs } from 'react-bootstrap';

export default function TabBasedFeature(props) {
    return (
        <div className="tab-feature-wrapper">
            <Tabs defaultActiveKey={props.defaultActiveKey} className="feature-tabs">
                {props.tabs.map(tab => {
                    return (
                        <Tab key={tab.key}
                            eventKey={tab.key}
                            title={tab.title}
                            disabled={tab.disabled && tab.disabled == 'true'}>
                            <tab.Component passedProps={props.passedProps} />
                        </Tab>)
                })}
            </Tabs>
        </div>

    )
}