import React, { useState, useEffect, useContext, useRef } from "react";
import { Form, Col, Row, Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import LocationDropDown from "./LocationDropDown";
import MemberPayerDropdown from "../../form/MemberPayerDropdown";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import { useLocation } from "react-router-dom";
export default function ManualRemittanceForm(props) {
    //NOTE: This is a placeholder for the selected payer
    const userContext = useContext(UserContext);
    const location = useLocation();
    // Property to store the form data
    const [paymentId, setPaymentId] = useState(props.paymentId);
    const [checkNumber, setCheckNumber] = useState("");
    const [checkDate, setCheckDate] = useState("");
    const [checkAmount, setCheckAmount] = useState("");
    const [selectedPayer, setSelectedPayer] = useState("");
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [paymentDate, setPaymentDate] = useState("");
    const [includePatientName, setIncludePatientName] = useState(false);
    const [includePaymenttype, setIncludePaymenttype] = useState(false);
    const [disableClaimWarning, setDisableClaimWarning] = useState(false);
    const [enableCheck, setEnableCheck] = useState(false);

    const [showResetCheckModal, setShowResetCheckModal] = useState(false);
    const [showUpdateCheckModal, setShowUpdateCheckModal] = useState(false);
    const [locationDataSource, setLocationDataSource] = useState([]);
    const [locationFormatedDataSource, setLocationFormatedDataSource] = useState([]);
    const [defaultSelected, setDefaultSelected] = useState([]);
    const [currentCheckData, setCurrentCheckData] = useState(props.currentCheckData);
    const [selectAllOption, setSelectAllOption] = useState(true);
    const [deSelectAll, setDeSelectAll] = useState(false);
    const [forceSubmit, setForceSubmit] = useState(false);

    const parentNameCheckBoxRef = useRef(null);
    const parentTypeCheckBoxRef = useRef(null);
    const claimNotFoundCheckBoxRef = useRef(null);

    function validateForm() {
        if (checkNumber == "") {
            return "Check Number is a required field.";
        }
        if (checkDate == "") {
            return "Check Date is a required field.";
        }
        if (checkAmount == "") {
            return "Check Amount is a required field.";
        }
        if (selectedLocations.length <= 0) {
            return "Location (NCPDP) is a required field.";
        }

        if (!validatelocation(selectedLocations)) {
            return "Please select locations (NCPDP) with same RPID";
        }

        if (startDate != "" && endDate != "") {
            // Date From cannot be greater tha end date
            if (new Date(startDate) > new Date(endDate))
                return "Start date cannot be greater than end date";
        }

        if (
            !selectedPayer ||
            !selectedPayer.value ||
            selectedPayer.value.length === 0
        ) {
            return "Payer is a required field";
        }
    }

    function validatelocation(selectedLocations) {
        var rpid = locationDataSource.filter(location => location.memberMmid == selectedLocations[0])[0].memberRpid
        for (var count = 0; count < selectedLocations.length; count++) {
            var result = locationDataSource.filter(location => location.memberMmid == selectedLocations[count]);
            if (result[0].memberRpid != rpid) {
                return false;
            }
        }
        return true;
    }

    function handleFormSubmit(e) {
        e.preventDefault();

        let errors = validateForm();
        errors ? alert(errors) : submitForm();
    }

    useEffect(() => {
        if (deSelectAll) {
            setDeSelectAll(false);
        }
    }, [deSelectAll]);

    useEffect(() => {
        props.handleEntryOptionsChange({
            includePatientName: includePatientName,
            includePaymenttype: includePaymenttype,
            selectedLocations: selectedLocations
        });
    }, [includePatientName, includePaymenttype, selectedLocations]);

    useEffect(() => {
        if (props.currentCheckData && locationFormatedDataSource.length > 0 && userContext.payers) {
            setCurrentCheckData(props.currentCheckData);
            setCheckNumber(props.currentCheckData.checkNumber);
            var date = new Date(props.currentCheckData.checkDate);
            setCheckDate(date);
            setCheckAmount(props.currentCheckData.checkAmount);
            setSelectedPayer({ label: props.currentCheckData.payerName, value: props.currentCheckData.pbid });

            var mMIDs = props.currentCheckData.mmid.split(",");
            var SelectedItem = [];
            var seletedLocations = [];
            if (mMIDs.length > 0) {
                for (var count = 0; count < mMIDs.length; count++) {
                    SelectedItem.push({
                        label: '', value: parseInt(mMIDs[count])
                    });
                    seletedLocations.push(parseInt(mMIDs[count]));
                }

            }
            setSelectedLocations(seletedLocations);
            setDefaultSelected(SelectedItem);
            setForceSubmit(true);
        }
    }, [props.currentCheckData, locationFormatedDataSource, userContext.payers]);

    useEffect(() => {
        setPaymentId(props.paymentId);
    }, [props.paymentId]);

    useEffect(() => {
        if (props.clearAll) {
            ClearForm();
        };
    }, [props.clearAll]);

    function resetCheckOnClick() {
        setShowResetCheckModal(true);
    }

    function ClearForm() {
        setCheckNumber("");
        setCheckDate("");
        setCheckAmount("");
        setSelectedLocations([]);
        setStartDate("");
        setEndDate("");
        setPaymentDate("");
        setSelectedPayer("");
        setDefaultSelected([]);
        setEnableCheck(false);
        setIncludePatientName(false);
        setIncludePaymenttype(false);
        setDisableClaimWarning(false);
        setPaymentDate("");
        parentNameCheckBoxRef.current.checked = false;
        parentTypeCheckBoxRef.current.checked = false;
        claimNotFoundCheckBoxRef.current.checked = false;
        setDeSelectAll(true);
    }

    function UpdateCheckOnClick() {
        if (paymentId == 0) {
            props.handleCreateCheck({
                paymentId: null,
                checkAmount: checkAmount,
                checkDate: checkDate,
                checkNumber: checkNumber,
                endDate: endDate == "" ? null : endDate,
                paymentDate: paymentDate == "" ? null : paymentDate,
                selectedLocations: selectedLocations.join(","),
                selectedPayers: selectedPayer.value,
                startDate: startDate == "" ? null : startDate,
                includePatientName: includePatientName,
                includePaymenttype: includePaymenttype,
                disableClaimWarning: disableClaimWarning,
                shouldUpdateCheck: false,
                shouldUpdateClaims: false
            });
        }
        else {
            setShowUpdateCheckModal(true);
        }
    }
    function handleResetCheckCloseModal() {
        setShowResetCheckModal(false)
    }
    function handleResetCheckOkModal() {
        setEnableCheck(false)
        props.handleResetCheck({
            paymentId: paymentId,
            selectedLocations: selectedLocations.join(","),
        });

        setShowResetCheckModal(false);
    }
    function handleUpdateCheckYesModal() {
        setShowUpdateCheckModal(false);
        props.handleCreateCheck({
            paymentId: paymentId,
            checkAmount: checkAmount,
            checkDate: checkDate,
            checkNumber: checkNumber,
            endDate: endDate == "" ? null : endDate,
            paymentDate: paymentDate == "" ? null : paymentDate,
            selectedLocations: selectedLocations.join(","),
            selectedPayers: selectedPayer.value,
            startDate: startDate == "" ? null : startDate,
            includePatientName: includePatientName,
            includePaymenttype: includePaymenttype,
            disableClaimWarning: disableClaimWarning,
            shouldUpdateCheck: true,
            shouldUpdateClaims: true
        });
    }
    function handleUpdateCheckNoModal() {
        setShowUpdateCheckModal(false);
        props.handleCreateCheck({
            paymentId: paymentId,
            checkAmount: checkAmount,
            checkDate: checkDate,
            checkNumber: checkNumber,
            endDate: endDate == "" ? null : endDate,
            paymentDate: paymentDate == "" ? null : paymentDate,
            selectedLocations: selectedLocations.join(","),
            selectedPayers: selectedPayer.value,
            startDate: startDate == "" ? null : startDate,
            includePatientName: includePatientName,
            includePaymenttype: includePaymenttype,
            disableClaimWarning: disableClaimWarning,
            shouldUpdateCheck: true,
            shouldUpdateClaims: false
        });
    }
    function handleUpdateCheckCancelModal() {
        setShowUpdateCheckModal(false);
    }

    function submitForm() {
        setEnableCheck(true);
        props.handleFormSubmit({
            checkAmount: checkAmount,
            checkDate: checkDate,
            checkNumber: checkNumber,
            endDate: endDate == "" ? null : endDate,
            paymentDate: paymentDate == "" ? null : paymentDate,
            selectedLocations: selectedLocations.join(","),
            selectedPayers: selectedPayer.value,
            startDate: startDate == "" ? null : startDate,
            includePatientName: includePatientName,
            includePaymenttype: includePaymenttype,
            disableClaimWarning: disableClaimWarning
        });
    }

    useEffect(() => {
        if (forceSubmit) {
            submitForm();
            setForceSubmit(false);
        }
    }, [forceSubmit]);

    useEffect(() => {
        getUserLocation();
    }, [userContext.currentOrganization.value])

    async function getUserLocation() {
        let allLocationsParameters = {
            email: props.passedProps.user.email,
            selectedItem: userContext.currentOrganization.value,
            organizationType: userContext.currentOrganization.type,
            programSubscriptions: "Rec2.0",
        };
        let accessToken = props.passedProps.auth.getAccessToken();
        let currentRoute = location.pathname;
        let allLocationsResponse = await axios.get(
            `api/UserProgram/GetUserLocationsDetails`,
            {
                params: allLocationsParameters,
                headers: { Authorization: `Bearer ${accessToken}`, RequestSrc: currentRoute },
            }
        );

        setLocationDataSource(allLocationsResponse.data);
        let allLocations = formatToArray(allLocationsResponse.data);
        setLocationFormatedDataSource(allLocations);
    }

    function formatToArray(data) {
        let formattedData = [];
        for (var count = 0; count < data.length; count++) {
            var Label = "";
            if (userContext.currentOrganization.type == 'Group') {
                Label = data[count].memberName + ' (' + data[count].memberNcpdp + ')' + ' RPID: ' + data[count].memberRpid + '';
            } else {
                Label = data[count].memberName + ' (' + data[count].memberNcpdp + ')'
            }
            formattedData.push({ label: Label, value: data[count].memberMmid });
        }
        return formattedData;
    }

    return (<Form onSubmit={handleFormSubmit}>

        <Row className="justify-content-start">

            <Col md="auto" className="d-flex flex-column">
                <Form.Label>Check Number</Form.Label>
                <Form.Control
                    type="text"
                    placeholder=""
                    onChange={(e) => {
                        setCheckNumber(e.target.value);
                    }}
                    value={checkNumber}
                />
            </Col>

            <Col md="auto" className="d-flex flex-column z-Index-13">
                <Form.Label>Check Date</Form.Label>
                <Form.Control
                    name="checkDate"
                    as={DatePicker}
                    selected={checkDate}
                    onChange={(e) => {
                        setCheckDate(e);
                    }}
                />
            </Col>
            <Col md="auto" className="d-flex flex-column">
                <Form.Label>Check Amount</Form.Label>
                <Form.Control
                    type="number"
                    onBlur={(e) => {
                        setCheckAmount(parseFloat(checkAmount).toFixed(2));
                    }}
                    onChange={(e) => {
                        setCheckAmount(e.target.value);
                    }}
                    value={checkAmount}
                />
            </Col>
            <Col md="auto" className="d-flex flex-column">
                <Form.Label>Location (NCPDP)</Form.Label>
                <Form.Control
                    as={LocationDropDown}
                    onChange={(locations) => setSelectedLocations(locations)}
                    dataSource={locationFormatedDataSource}
                    defaultSelected={defaultSelected}
                    selectAllOption={selectAllOption}
                    deSelectAll={deSelectAll}
                />
            </Col>
            <Col md="auto" className="d-flex flex-column z-Index-12">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                    name="startDate"
                    as={DatePicker}
                    selected={startDate}
                    onChange={(e) => {
                        setStartDate(e);
                    }}
                />
            </Col>
            <Col md="auto" className="d-flex flex-column z-Index-12">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                    name="endDate"
                    as={DatePicker}
                    selected={endDate}
                    onChange={(e) => {
                        setEndDate(e);
                    }}
                />
            </Col>
        </Row>
        <Row className="justify-content-start">
            <Col md="auto" className="d-flex flex-column">
                <Form.Label>Payer</Form.Label>
                <Form.Control
                    as={ComboBox}
                    data={userContext.payers || []}
                    id="value"
                    textField="label"
                    allowCustom={false}
                    style={{
                        //width: "30em",
                        textSizeAdjust: "small",
                    }}
                    value={selectedPayer}
                    onChange={(e) => {
                        if (e.target.value) {
                            setSelectedPayer(e.target.value);
                        } else setSelectedPayer(null);
                    }}
                />
            </Col>
            <Col md="auto" className="d-flex flex-column z-Index-11">
                <Form.Label>Payment Date</Form.Label>
                <Form.Control
                    name="paymentDate"
                    as={DatePicker}
                    selected={paymentDate}
                    onChange={(e) => {
                        setPaymentDate(e);
                    }}
                />
            </Col>
            <Col md="auto" className="d-flex flex-column">
                <Form.Label>Entry Options</Form.Label>
                <Form.Check type="checkbox" label="Include Patient Name" ref={parentNameCheckBoxRef}
                    onChange={(event) => {
                        setIncludePatientName(event.target.checked);
                    }
                    }
                />
                <Form.Check type="checkbox" label="Include Payment Type"
                    onChange={(event) => { setIncludePaymenttype(event.target.checked); }} ref={parentTypeCheckBoxRef} />
                <Form.Check type="checkbox" label="Disable 'Claim not Found' warning"
                    onChange={(event) => { setDisableClaimWarning(event.target.checked); }} ref={claimNotFoundCheckBoxRef} />
            </Col>
            <Col md="auto" className="d-flex flex-column">
                <Button className="inline-show-result-button" size="sm" type="submit" disabled={enableCheck}>
                    Show Results
                </Button>
            </Col>
            <Col md="auto" className="d-flex flex-column">
                <Button className="inline-show-result-button" size="sm" type="button" disabled={!enableCheck} onClick={resetCheckOnClick}>
                    Reset Check
                </Button>
            </Col>
            <Col md="auto" className="d-flex flex-column">
                <Button className="inline-show-result-button" size="sm" type="button" disabled={!enableCheck} onClick={UpdateCheckOnClick}>
                    {paymentId == 0 ? "Create Check" : "Update Check"}
                </Button>
            </Col>
        </Row>
        <Modal show={showResetCheckModal} onHide={() => { setShowResetCheckModal(false) }}>
            <Modal.Header closeButton>
                <h4>Reset Remittance Advice</h4>
            </Modal.Header>
            <Modal.Body>
                Click 'Ok' will reset remittance advice and all the changes will be lost.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleResetCheckOkModal}>
                    Ok
                </Button>
                <Button variant="secondary" onClick={handleResetCheckCloseModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showUpdateCheckModal} onHide={() => { setShowUpdateCheckModal(false) }}>
            <Modal.Header closeButton>
                <h4>Update check for Manual Remittance</h4>
            </Modal.Header>
            <Modal.Body>
                Do you also want to reset claims for this manual remittance?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleUpdateCheckYesModal}>
                    Yes
                </Button>
                <Button variant="secondary" onClick={handleUpdateCheckNoModal}>
                    No
                </Button>
                <Button variant="secondary" onClick={handleUpdateCheckCancelModal}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    </Form>

    )
}