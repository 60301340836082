let allowedEraRoutes = [
  "/",
  "/RemitLookUp",
  "/MissingRemittances",
  "/835RemittanceExporting",
  "/ManualRemittance",
  //NOTE: This is for handling any "Admin only" routes, we don't want to prevent usage for internal UCT users
  "/AdminExportBatchQueueMaintenance",
  "/AdminRemittanceLookup",
];

//returns true/false depending on if the route is in the allowedEraRoutes array
function IsUnrestrictedEraRoute(route) {
  return allowedEraRoutes.includes(route);
}
export { IsUnrestrictedEraRoute };
