import React, { useState } from "react";
import DatePicker from "react-datepicker";
import StatusDropdown from "../../MultiSelectDropdown";
import { Form, Row, Col, Button } from "react-bootstrap";
import MemberLocationDropdown from "../../form/MemberLocationDropdown";
import MemberPayerDropdown from "../../form/MemberPayerDropdown";
import { IsNullOrWhitespace } from "../../../helpers/StringHelpers";
import { isNull } from "lodash";

export default function RxLookupForm(props) {
  const [selectedPayers, setSelectedPayers] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const [rxNumber, setRxNumber] = useState("");
  const [dateType, setDateType] = useState("dispense");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  //NOTE: These are temporary date values, remove them later once dev work completes
  // const [fromDate, setFromDate] = useState(new Date(2021, 5, 1));
  // const [toDate, setToDate] = useState(new Date(2021, 5, 15));

  const [bin, setBin] = useState("");
  const [ndc, setNdc] = useState("");
  const [patientLastName, setPatientLastName] = useState("");

  function validateForm() {
    if (selectedLocations.length <= 0) {
      return "Location (NCPDP) is a required field.";
    }

    //NOTE: If an Rx Number is provided, nothing else needs to be checked
    if (!(IsNullOrWhitespace(rxNumber) && selectedLocations.length > 0)) {
      return null;
    }
    //NOTE: If no rxNumber is provided, then we need to check if a date range is provided
    //If a date range is provided, then either a BIN, Payer, or NDC value needs to be submitted
    else {
      if (!fromDate && !toDate) {
        return ("If no Rx Number is provided, then a date range must be provided.");
      }
      else if (fromDate && !toDate) {
        return ("A start date is provided, but an end date must also be provided.");
      }
      else if (!fromDate && toDate) {
        return ("An end date is provided, but a start date must also be provided.");
      }
      //NOTE: If a date range is provided, then there must also be a Bin/Payer/NDC value
      else {
        if (toDate < fromDate) {
          return ("The end date must be after the start date.");
        }
        else if (
          IsNullOrWhitespace(bin) &&
          selectedPayers.length <= 0 &&
          IsNullOrWhitespace(ndc) &&
          IsNullOrWhitespace(patientLastName)
          ) {
          return ("If a date range is provided but no Rx Number is provided, then a BIN, payer, NDC, or patient last name parameter must be included.");
        }
      }
    }

    return null;
  }

  function handleFormSubmit(e) {
    e.preventDefault();

    let errors = validateForm();
    errors ? alert(errors) : submitForm();
  }

  function submitForm() {
    props.handleFormSubmit({
      rxNumber: rxNumber,
      payers: selectedPayers.join(","),
      locations: selectedLocations.join(","),
      dateType: dateType,
      fromDate: fromDate ? fromDate.toLocaleDateString() : "",
      toDate: toDate ? toDate.toLocaleDateString() : "",
      bin: bin ? bin : "",
      ndc: ndc ? ndc : "",
      patientLastName: patientLastName ? patientLastName : ""
    });
  }

  return (
    <Form onSubmit={handleFormSubmit}>

      <Row className="justify-content-start">

        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Rx Number</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            onChange={(e) => {
              setRxNumber(e.target.value);
            }}
            value={rxNumber}
          />
        </Col>

        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Date Type</Form.Label>
          {/* <Form.Select aria-label="Default select example"
            onChange={(e) => {
              setDateType(e.target.value);
            }}>
            <option value="dispense">Dispense Date</option>
            <option value="transaction">Transaction Date</option>
          </Form.Select> */}
          <div key="DateType">
            <Form.Check
              custom="true"
              type="radio"
              id="rxLookupDateType-1"
              name="DateType"
              label="Dispense date"
              value="dispense"
              onChange={(event) => setDateType(event.target.value)}
              defaultChecked
            />
            <Form.Check
              custom="true"
              type="radio"
              id="rxLookupDateType-1"
              name="DateType"
              label="Transaction date"
              value="transaction"
              onChange={(event) => setDateType(event.target.value)}
            />
          </div>

        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Start Date</Form.Label>
          <Form.Control
            name="fromDate"
            as={DatePicker}
            selected={fromDate}
            onChange={(date) => {
              setFromDate(date);
            }}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>End Date</Form.Label>
          <Form.Control
            name="fromDate"
            as={DatePicker}
            selected={toDate}
            onChange={(date) => {
              setToDate(date);
            }}
          />
        </Col>

        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Payer</Form.Label>
          <Form.Control
            as={MemberPayerDropdown}
            onChange={(payers) => setSelectedPayers(payers)}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Location (NCPDP)</Form.Label>
          <Form.Control
            as={MemberLocationDropdown}
            onChange={(locations) => setSelectedLocations(locations)}
            programSubscription={"Rec2.0"}
          />
        </Col>

        <Col md="auto" className="d-flex flex-column">
          <Form.Label>BIN</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            onChange={(e) => {
              setBin(e.target.value);
            }}
            value={bin}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>NDC</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            onChange={(e) => {
              setNdc(e.target.value);
            }}
            value={ndc}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Patient Last Name</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            onChange={(e) => {
              setPatientLastName(e.target.value);
            }}
            value={patientLastName}
          />
        </Col>
      </Row>
      <Row>
        <Col md="auto" className="d-flex flex-column">
          <Button className="inline-show-result-button" size="sm" type="submit">
            Show Results
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
