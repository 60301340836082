import axios from "axios";
import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Accordion from "../../Accordion";
import MissingRemittancesActiveForm from "./MissingRemittancesActiveForm";

import MissingRemittanceDownloadAttachment from "./MissingRemittanceDownloadAttachment.js";

import MissingRemittanceStatusHistoryModal from "./MissingRemittanceStatusHistoryModal";
import MissingRemittanceAddEditModal from "./MissingRemittanceAddEditModal";

import { Link } from "react-router-dom";
import { GridColumn } from "@progress/kendo-react-grid";
import SharedMainSelectableGridNewImplementation, {
  AddGridButton,
} from "../../grid/Kendo/SharedMainSelectableGridNewImplementation";
import { LoadingSpinner } from "../../layout/LoadingSpinner";
import { IsNullOrWhitespace } from "../../../helpers/StringHelpers";
import { constants } from "./MissingRemittanceActiveConstants";

export function MissingRemittancesActive(props) {
  const { passedProps } = props;

  const { statusUpdated, setStatusUpdated } = passedProps;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const [submittedFormData, setSubmittedFormData] = useState(null);

  const [gridData, setGridData] = useState([]);

  const [gridButtons, setGridButtons] = useState(null);

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [detailsPaymentId, setDetailsPaymentId] = useState(null);

  const [
    showAddNewMissingRemittanceModal,
    setShowAddNewMissingRemittanceModal,
  ] = useState(false);

  const [missingRemittanceEditId, setMissingRemittanceEditId] = useState(false);
  const [missingRemittanceEditItemData, setMissingRemittanceEditItemData] =
    useState(false);

  useEffect(() => {
    if (!submittedFormData || !submittedFormData.locations) return;

    setIsLoading(true);
    getMainGridData();
  }, [submittedFormData, statusUpdated]);

  useEffect(() => {
    if (!gridButtons) {
      let buttonsArray = GetGridButtons();

      setGridButtons(buttonsArray);
    }
  }, [gridButtons]);

  //return an array of buttons for the grid
  function GetGridButtons() {
    let buttonsArray = [];

    buttonsArray = AddGridButton(
      buttonsArray,
      "Move Selected to History",
      handleMoveSelectedToHistory
    );

    buttonsArray = AddGridButton(
      buttonsArray,
      "Close Selected Requests",
      handleCloseSelected
    );

    return buttonsArray;
  }

  function getMainGridData() {
    try {
      let accessToken = passedProps.auth.getAccessToken();

      let parameters = {
        locations: submittedFormData.locations,
      };

      axios
        .get("api/MissingRemittances/GetActiveRequests", {
          params: parameters,
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  function onSuccess(response) {
    //alert("onSuccess submittedFormData " + submittedFormData.locations);

    let columns = [];
    let data = response.data;

    //console.log(data);

    if (data.length > 0) {
      const headerProps = constants.headerProps;

      //console.log("headerProps", headerProps);

      Object.keys(data[0]).map((key) => {
        //alert("key " + key);
        //alert("key " + key + " headerProps[key] " + headerProps[key]);

        if (headerProps[key] && headerProps[key].title) {
          return columns.push({
            field: key,
            title: headerProps[key].title,
            type: headerProps[key].type,
            fixedLeft: headerProps[key].fixedLeft,
            hidden: headerProps[key].hidden,
            showTotal: headerProps[key].showTotal,
            truncatable: headerProps[key]
              ? headerProps[key].truncatable
              : false,
            isLocked: headerProps[key].isLocked,
          });
        }
      });
      setError({});
    } else {
      setError({ status: 201, Message: "No data found for given params" });
    }

    let dataWithCleanedDates = response.data.map((t) => {
      return {
        ...t,
        requestDate: IsNullOrWhitespace(t.requestDate)
          ? ""
          : new Date(Date.parse(t.requestDate)),

        checkDate: IsNullOrWhitespace(t.checkDate)
          ? ""
          : new Date(Date.parse(t.checkDate)),
      };
    });

    // console.log("dataWithCleanedDates", dataWithCleanedDates);

    //setGridColumns(columns);
    setGridData(dataWithCleanedDates);

    //console.log("dataWithCleanedDates", dataWithCleanedDates);
    setIsLoading(false);
  }

  function onFailure(error) {
    alert("onFailure error " + error);
    setIsLoading(false);

    //console.log(error);
  }

  //Will bring up the modal with the history of the status of the missing remittance
  const StatusHistoryActionCell = (props) => {
    async function viewClick() {
      setDetailsPaymentId(props.dataItem["paymentId"]);

      setShowDetailsModal(true);
    }

    return (
      <td className="noWrap missingRemittanceWiderGridColumns" {...props.style}>
        <a
          onClick={viewClick}
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "#015CAB",
            whiteSpace: "nowrap",
          }}
        >
          {props.dataItem["status"]}
        </a>
      </td>
    );
  };

  const AttachmentDownloadCell = (props) => {
    return (
      <td className="noWrap centered " {...props.style}>
        {props.dataItem["hasAttachment"] && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              MissingRemittanceDownloadAttachment(
                props.dataItem["paymentId"],
                props.dataItem["attachedFileName"],
                passedProps.auth
              )
            }
          >
            <i class="fa fa-download" aria-hidden="true"></i>{" "}
          </span>
        )}
      </td>
    );
  };

  function handleAddEditMissingRemittance(itemId) {
    // Add your logic here

    //when we add a new missing remittance, we want to set the edit id to -1
    setMissingRemittanceEditId(itemId);

    if (itemId > 0) {
      //get the grid record where paymentId matches the itemId
      let gridEditRecord = gridData.find((x) => x.paymentId === itemId);

      setMissingRemittanceEditItemData(gridEditRecord);
    }

    setShowAddNewMissingRemittanceModal(true);
    //alert("Add New Missing Remittance");
  }

  function OnUpdateSuccess() {
    //alert(json.stringify(response));
    //generate a random string

    var randomString = Math.random().toString(36).substring(7);
    setStatusUpdated(randomString);
  }

  //NOTE TODO: This was the original logic for generating a "serverside export request",
  //but this was disabled once all grids were standardized to current "Download As..." functionality, restore this later as desired
  async function MoveSelectedToHistory(selectedRemittances, status) {
    //convert the selected remittances array to a comma separated string
    let selectedRemittanceIds = selectedRemittances.join(",");

    let accessToken = passedProps.auth.getAccessToken();

    let request = {
      ids: selectedRemittanceIds,
      user: passedProps.user.email,
      newremittancestatus: status,
    };

    // console.log("request", request);
    // alert(
    //   "MoveSelectedToHistory called with " +
    //     selectedRemittances.length +
    //     " records."
    // );

    try {
      await axios
        .post("api/MissingRemittances/MoveActiveToHistory", request, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(() => {
          OnUpdateSuccess();
        })
        .catch((error) => {
          onFailure(error);
        });
    } catch (error) {
      onFailure(error);
    }
  }

  const handleMoveSelectedToHistory = (selectGridRecords) => {
    let status = "Manually Received";

    MoveSelectedToHistory(selectGridRecords, status);
  };

  const handleCloseSelected = (selectGridRecords) => {
    let status = "Closed by Customer";

    MoveSelectedToHistory(selectGridRecords, status);
  };

  const ActionCell = (props) => {
    async function editClick() {
      handleAddEditMissingRemittance(props.dataItem["paymentId"]);
    }

    return (
      <td className="noWrap" {...props.style}>
        <a
          onClick={editClick}
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "#015CAB",
          }}
        >
          Edit/Update
        </a>
      </td>
    );
  };

  return (
    <div className="feature-tab" id="request-exports">
      <LoadingSpinner isDataLoading={isLoading} controlsName={"activeTab"} />

      <Accordion generalInfoDefaultExpanded label="Info*">
        <Row>
          <Col className="higher-zindex-filters">
            <div>
              <ul className="bullet-info-list">
                <li>
                  If you were unable to locate your remittance using{" "}
                  <Link to="/RemitLookUp">Remittance Lookup</Link>, and it has
                  been over <strong>72 hours</strong> since the deposit date,
                  please add a &#8220;New Missing Remittance&#8221; below.
                </li>

                <li>
                  If you are missing 835 file export (rather than remittance),
                  please navigate to{" "}
                  <Link to="/835RemittanceExporting">Export Remittances</Link>.
                </li>
                <li>
                  If you are missing a physical check/deposit (rather than a
                  remittance), please reach out to the payer.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Accordion>

      <Accordion defaultExpanded label="Search & Filter">
        <Row>
          <Col className="higher-zindex-filters spacer_bottom-padding-oneline">
            <MissingRemittancesActiveForm
              handleFormSubmit={setSubmittedFormData}
            />
          </Col>
        </Row>
      </Accordion>

      <Accordion defaultExpanded label="Note">
        <Row>
          <Col>
            <div>
              <ul className="bullet-info-list">
                <li>
                  Please check the status column for updates and action items.
                  After two weeks, or for urgent requests, email for follow up
                  can be sent to{" "}
                  <a href="mailto:missingremits@net-rx.com">
                    missingremits@net-rx.com
                  </a>
                  {". "}
                  Please include Request Number when emailing
                  missingremits@net-rx.com.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Accordion>

      <Row>
        <Col className="spacer_bottom-padding-oneline">
          <button
            title="Add New Missing Remittance"
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
            onClick={() => {
              handleAddEditMissingRemittance(-1);
            }}
          >
            Add New Missing Remittance
          </button>
        </Col>
      </Row>

      <Row>
        <Col className="tight-grid multi-line-filter">
          <SharedMainSelectableGridNewImplementation
            data={gridData}
            dataItemKey={"paymentId"}
            aggregateColumnSettings={null}
            sendAggregatesParentCallback={() => {}}
            sendSelectablesParentCallback={() => {}}
            exportFileNamePrefix={"MissingRemittancesActive"}
            gridButtons={gridButtons}
          >
            <GridColumn
              field=""
              title="Action"
              filterable={false}
              sortable={false}
              groupable={false}
              cells={{ data: ActionCell }}
            />

            <GridColumn
              key={"paymentId"}
              field={"id"}
              title={"id"}
              hidden={true}
              width={0}
            />

            <GridColumn
              field="status"
              title="Status"
              filterable={true}
              sortable={true}
              groupable={false}
              className="missingRemittanceWiderGridColumns"
              cells={{ data: StatusHistoryActionCell }}
            />
            <GridColumn key={"ncpdp"} field={"ncpdp"} title={"NCPDP"} />

            <GridColumn
              key={"paymentId"}
              field={"paymentId"}
              title={"Request Number"}
            />

            <GridColumn
              key={"payer"}
              field={"payer"}
              title={"Payer"}
              className="missingRemittanceWiderGridColumns"
            />
            <GridColumn
              key={"centralPay"}
              field={"centralPay"}
              title={"Central Pay"}
              filter="boolean"
            />
            <GridColumn
              key={"checkNumber"}
              field={"checkNumber"}
              title={"Check Number"}
            />

            <GridColumn
              key={"checkDate"}
              field={"checkDate"}
              title={"Check Date"}
              filter="date"
              format="{0:d}"
            />

            <GridColumn
              key={"checkAmount"}
              field={"checkAmount"}
              title={"Check Amount"}
              filter="numeric"
            />

            <GridColumn
              key={"requestor"}
              field={"requestor"}
              title={"Requestor"}
              className="missingRemittanceWiderGridColumns"
            />

            <GridColumn
              key={"requestDate"}
              field={"requestDate"}
              title={"Request Date"}
              filter="date"
              format="{0:d}"
            />
            <GridColumn
              key={"hasAttachment"}
              field={"hasAttachment"}
              title={"Attachment"}
              filter="boolean"
              sortable={true}
              groupable={false}
              cells={{ data: AttachmentDownloadCell }}
            />

            <GridColumn
              key={"received"}
              field={"received"}
              title={"Received"}
              filter="boolean"
            />

            {/* <GridColumn key={"status"} field={"status"} title={"Status"} /> */}
          </SharedMainSelectableGridNewImplementation>
        </Col>
      </Row>

      <MissingRemittanceStatusHistoryModal
        paymentId={detailsPaymentId}
        auth={passedProps.auth}
        show={showDetailsModal}
        onClose={() => {
          setShowDetailsModal(false);
        }}
      ></MissingRemittanceStatusHistoryModal>

      <MissingRemittanceAddEditModal
        auth={passedProps.auth}
        show={showAddNewMissingRemittanceModal}
        onClose={() => {
          setShowAddNewMissingRemittanceModal(false);
        }}
        setStatusUpdated={setStatusUpdated}
        missingRemittanceEditId={missingRemittanceEditId}
        missingRemittanceEditItemData={missingRemittanceEditItemData}
      ></MissingRemittanceAddEditModal>
    </div>
  );
}
