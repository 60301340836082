import React, { useState, useRef, useContext, useEffect } from 'react';
import { UserContext } from '../../context/UserContext';
import axios from "axios";

export function Notifications(props) {

    return (
        <span className="action-link-with-icon">
            <i className="fa fa-bell-o" />
        </span>
    );
}