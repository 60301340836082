import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import { LogError } from "../../AppInsights/TelemetryService";
import { ShortDateFormat, IsDefaultDate } from "../../../helpers/DateHelpers";
import { FormatValue } from "../../../helpers/DataFormatHelpers";
import "./PendingHomePageRemittances.css";

export default function PendingHomePageRemittances(props) {
  const userContext = useContext(UserContext);

  const { passedProps } = props.passedProps;
  const [summaryData, setSummaryData] = useState();
  const [dataFetchErrored, setDataFetchErrored] = useState(false);

  function onFailure(error) {
    LogError(error);
    setDataFetchErrored(true);
  }

  function onSuccess(response) {
    setSummaryData(response.data);
  }

  async function getData() {
    let accessToken = passedProps.auth.getAccessToken();
    // console.log("metricLocations", userContext.metricLocations);
    let selectedLocations = userContext.metricLocations.map(x=>x.value).join(",");
    await axios
      .get("api/RemittanceLookup/GetRemittanceSummary", {
        params: {
          locations: selectedLocations,
        },
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(onSuccess)
      .catch(onFailure);
  }

  useEffect(() => {
    
    if(userContext.metricLocations && userContext.metricLocations.length > 0) {
      getData();
    }
    
  }, [userContext]);

  useEffect(() => {}, [summaryData]);

  return summaryData ? (
    <div className="remittanceSummaryContainer">
      <Row>
        <Col>Count of Pending Remits:</Col>
        <Col className="remittancecCountColor">
          {summaryData.remitsCount.toLocaleString()}
        </Col>
      </Row>

      <Row>
        <Col>Total Dollar Value:</Col>
        <Col className="remittancecDollarValueColor">
          {FormatValue(summaryData.totalDollarValue, "money")}
        </Col>
      </Row>

      <Row>
        <Col>Oldest Pending Check Date:</Col>
        <Col className="remittancecEarliestDateColor">
          {IsDefaultDate(summaryData.oldestCheckDate)
            ? "N/A"
            : ShortDateFormat(summaryData.oldestCheckDate)}
        </Col>
      </Row>
    </div>
  ) : (
    <div className="remittanceSummaryContainer">
      {dataFetchErrored ? (
        <div className="errorContainer">
          <p style={{ fontStyle: "italic" }}>
            Data is currently not available. Please try again later.
          </p>
        </div>
      ) : (
        <div className="loadingHomePageRemittancesContainer">
          <div className="loadingHomePageRemittancesLoader"></div>
        </div>
      )}
    </div>
  );
}
