import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
// import DataTable from "../../grid/DataTable";
import { RequestExportsForm } from "./RequestExportsForm";
import { RequestExportsDetailGrid } from "./RequestExportsDetailGrid";
import ErrorHandler from "../../core/ErrorHandler";
import { LoadingSpinner } from "../../layout/LoadingSpinner";

import { IsNullOrWhitespace } from "../../../helpers/StringHelpers";
import { StringToDate } from "../../../helpers/DateHelpers";

import { UserContext } from "../../context/UserContext";
import {ParentCompanyDisplayElement} from "./ParentCompanyDisplayElement";

export function RequestExports(props) {
  const { passedProps } = props;
  const user = passedProps.user.email;
  const userConfig = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const [submittedFormData, setSubmittedFormData] = useState(null);
  const [showCustomForm, setShowCustomForm] = useState(false);
  const [submittedRpid, setSubmittedRpid] = useState(null);
  const [showRequestExportsDetailsGrid, setShowRequestExportsDetailsGrid] = useState(false);

  const [mainGridData, setMainGridData] = useState([]);

  function handleFormSubmit(e) {
    setIsLoading(true);
    setSubmittedFormData(e);
  }

  useEffect(() => {
    getMainGridData();
  }, [submittedFormData]);

  function getMainGridData() {
    if (submittedFormData) {
      try {
        let accessToken = passedProps.auth.getAccessToken();

        /*
        NOTE: We need to retain this here because the user COULD
        theoretically change the header dropdown value AFTER they make a search.
        */
        let selectedRpid = (passedProps.parentCompanySetByGroupUser && passedProps.parentCompanySetByGroupUser.rpid) ?
        passedProps.parentCompanySetByGroupUser.rpid : userConfig.currentOrganization.value;
        
        setSubmittedRpid(selectedRpid);

        let parameters = {
          user: user,
          payers: submittedFormData.selectedPayers.join(","),
          locations: submittedFormData.selectedLocations.join(","),
          startDate: submittedFormData.fromDate ? submittedFormData.fromDate.toLocaleDateString() : "",
          endDate: submittedFormData.toDate ? submittedFormData.toDate.toLocaleDateString() : "",
          dateType: submittedFormData.dateType,
          remittanceLookup: submittedFormData.remittanceLookup,
          remitStatus: submittedFormData.remitStatus,
          exportStatus: submittedFormData.exportStatus
        };

        axios.get("api/RemittanceExport/Get", {
          params: parameters,
          headers: { Authorization: `Bearer ${accessToken}` },
        })
          .then(presentGridData)
          .catch(onFailure);
      }
      catch (exception) {
        onFailure(exception);
      }
    }
  }

  function presentGridData(response) {
    setIsLoading(false);

    if (response.data.length > 0) {
      let dataWithCleanedDates = response.data.map((t) => {
        return {
          ...t,
          batchRequestDate: IsNullOrWhitespace(t.batchRequestDate)
            ? "" : StringToDate(t.batchRequestDate),
          checkDate: IsNullOrWhitespace(t.checkDate)
            ? "" : StringToDate(t.checkDate),
          remitDate: IsNullOrWhitespace(t.remitDate)
            ? "" : StringToDate(t.remitDate),
          paymentDate: IsNullOrWhitespace(t.paymentDate)
            ? "" : StringToDate(t.paymentDate),
          archiveDate: IsNullOrWhitespace(t.archiveDate)
            ? "" : StringToDate(t.archiveDate),
        };
      });

      setMainGridData(dataWithCleanedDates);
      setShowRequestExportsDetailsGrid(true);
    }
    else {
      setError({ status: 201, Message: "No data found for given params" });
      setMainGridData([]);
    }

  }

  const onFailure = (error) => {
    setIsLoading(false);

    if (error.response) {
      switch (error.response.config.url) {
        case "api/RemittanceExport/Get":
          setError(error);
          break;
        case "api/RemittanceExport/CreateNewRequestForAllUnexportedRemittances":
          setError(error);
          break;
        case "api/RemittanceExport/CreateNewRequestForSelectedRemittances":
          setError(error);
          break;
        case "api/RemittanceExport/GetFileByBatchId":
          setError(error.response);
          break;
        default:
          //NOTE: There is just a default case, check the switch in the ErrorHandler's useEffect
          setError({ status: 400 });
          break;
      }
    }
    else {
      setError({ status: 400 });
    }
  }

  function createNewRequestForAllUnexportedRemittances() {
    var userWantsToContinue = window.confirm("Are you sure you want to Build un-exported 835s?");

    if (userWantsToContinue) {
      setIsLoading(true);

      let accessToken = passedProps.auth.getAccessToken();

      let selectedRpid = passedProps.parentCompanySetByGroupUser.rpid ?
      passedProps.parentCompanySetByGroupUser.rpid : userConfig.currentOrganization.value;

      setSubmittedRpid(selectedRpid);

      axios.post("api/RemittanceExport/CreateNewRequestForAllUnexportedRemittances", {
        user: user,
        rpid: selectedRpid
      }, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
        .then(handleSuccessNewRequestForAllUnexportedRemittances)
        .catch(onFailure);
    }

  }

  function handleSuccessNewRequestForAllUnexportedRemittances(response) {
    setIsLoading(false);

    if (response.status === 200) {
      setError({ status: response.status, Message: response.data.response });
    }

  }

  return (
    <div className="feature-tab" id="request-exports">
      <LoadingSpinner isDataLoading={isLoading} controlsName={"RequestExports"} />
      <ErrorHandler
        error={error}
        onClose={() => {
          setError({});
        }}
      />
      {(
        (passedProps.parentCompanySetByGroupUser && 
          passedProps.parentCompanySetByGroupUser.parentCompanyName)
      ? ParentCompanyDisplayElement(passedProps.parentCompanySetByGroupUser.parentCompanyName, () => passedProps.resetValuesSelectedByGroupUserCallback()) : null
      )}

      <div key="DateType">
        <Form.Check
          custom="true"
          type="radio"
          id="exportOption-1"
          name="exportOption"
          label="Export all unexported 835s"
          value="exportAll"
          onChange={(event) => setShowCustomForm(false)}
          defaultChecked
        />
        <Form.Check
          custom="true"
          type="radio"
          id="exportOption-2"
          name="exportOption"
          label="Request custom exports 835s"
          value="exportCustom"
          onChange={(event) => setShowCustomForm(true)}
        />

      </div>

      <div hidden={showCustomForm}>
        <Button className="inline-show-result-button" size="sm" onClick={() => createNewRequestForAllUnexportedRemittances()}>
          Export All 835s
        </Button>
      </div>

      <div hidden={!showCustomForm}>
        <RequestExportsForm handleFormSubmit={handleFormSubmit} passedProps={passedProps} />

        <div hidden={!showRequestExportsDetailsGrid}>
          <RequestExportsDetailGrid
            data={mainGridData}
            user={user}
            getAccessToken={() => passedProps.auth.getAccessToken()}
            onFailure={onFailure}
            setParentIsLoading={(isLoading) => setIsLoading(isLoading)}
            submittedFormData={submittedFormData}
            submittedRpid={submittedRpid}
            setErrorFromParent={setError}
            getMainGridDataFromParent={getMainGridData}
          />
        </div>
      </div>


    </div>
  );

}