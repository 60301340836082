import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Button, Form, Container } from "react-bootstrap";
import axios from "axios";
// import DataTable from "../../grid/DataTable";
import { CurrentAgingForm } from "./CurrentAgingForm";
import ErrorHandler from "./../../core/ErrorHandler";

import { DetailsModal } from "../../grid/DetailsModal";
import { LoadingSpinner } from "./../../layout/LoadingSpinner";
import { IgnoreModal } from "../SharedComponents/IgnoreModal";
import SingleSelectDropdown from "../../SingleSelectDropdown";
import Accordion from "../../Accordion";
import { IsNullOrWhitespace } from "../../../helpers/StringHelpers";
import IgnoreOptions from "../SharedComponents/IgnoreOptions";
import SharedMainGrid from "../../grid/Kendo/SharedMainGrid";

import {
  Grid,
  GridColumn,
  GridToolbar,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { process, aggregateBy } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { getter, ZIndexContext } from "@progress/kendo-react-common";
import { LogError } from "../../../../src/components/AppInsights/TelemetryService";

import GridExport from "../../export/GridExport";

import {
  MoneyCell,
  AggregateMoneyFooterCell,
} from "../../grid/Kendo/CustomKendoGridCells";

import {DefaultColumnWidth, DefaultDateColumnWidth, DefaultBooleanOrShortWidthColumnWidth} from "../../grid/Kendo/KendoGridAndColumnConstants";
import { set } from "lodash";

export function CurrentAging(props) {
  const { passedProps } = props;
  const user = passedProps.user.email;
  const DETAILS_SELECTED_FIELD = "SelectedClaimId";
  const DETAILS_DATA_ITEM_KEY = "claimId";
  const idGetter = getter(DETAILS_DATA_ITEM_KEY);
  const INITIAL_DETAILS_GRID_DATA_STATE = { skip: 0, take: 50 };

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const [submittedFormData, setSubmittedFormData] = useState({});
  const [detailError, setDetailError] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showIgnoreCount, setShowIgnoreCount] = useState(false);
  const [showIgnoreModal, setShowIgnoreModal] = useState(false);
  const [IgnoreError, setIgnoreError] = useState({});
  const [selectedIgnoreOption, setSelectedIgnoreOption] = useState("");
  const [ignoreNote, setIgnoreNote] = useState("");
  const [selectedNcpdpIgnoreModalText, setSelectedNcpdpIgnoreModalText] =
    useState("");
  const [selectedRxIgnoreModalText, setSelectedRxIgnoreModalText] =
    useState("");
  const [selectedClid, setSelectedClid] = useState("");
  const [selectedDetailProps, setSelectedDetailProps] = useState("");

  const [exportData, setExportData] = useState([]);
  const [exportColumns, setExportColumns] = useState([]);

  const [
    ignoreMultipleSelectedClaimsInDetailModal,
    setIgnoreMultipleSelectedClaimsInDetailModal,
  ] = useState(false);

  /*
  NOTE: The "mainGridData" state variable holds the "data in the grid at all times
  regardless of filtering, sorting, etc."
  Current Aging grid's columns need to be specified in the state because
  they are based on aging periods and change from one pharmacy to another.
  The money columns on the main grid specifically are used for aggregating.
  */
  const [mainGridData, setMainGridData] = useState([]);
  const [mainGridColumns, setMainGridColumns] = useState([]);
  const [
    mainGridMoneyColumnAggregateSettings,
    setMainGridMoneyColumnAggregateSettings,
  ] = useState([]);
  const [mainGridAggregates, setMainGridAggregates] = useState([]);
  const [mainGridFilters, setMainGridFilters] = useState(null);

  const [detailsGridParameters, setDetailsGridParameters] = useState({});
  const [detailsGridData, setDetailsGridData] = useState([]);
  const [detailsSummaryData, setDetailsSummaryData] = useState([]);
  const [detailsGridTitle, setDetailsGridTitle] = useState("");
  const [selectedDetailsState, setSelectedDetailsState] = useState({});
  const [detailsGridAggregates, setDetailsGridAggregates] = useState([]);
  const [detailsGridDataState, setDetailsGridDataState] = useState({});
  const [detailsGridResultState, setDetailsGridResultState] =
    useState(detailsGridData);

  const _container = React.useRef();
  const _detailsGrid = React.useRef();
  const _detailsExport = React.useRef(null);

  const sendAggregatesToParent = (aggregatesFromSharedMainAgingGrid) => {
    setMainGridAggregates(aggregatesFromSharedMainAgingGrid.data);

    //NOTE: This is really just needed because we need to get the list of BIN-PCN combos used for the details data call for the aggregates
    setMainGridFilters(aggregatesFromSharedMainAgingGrid.filter);
  };

  //NOTE TODO: This was the original logic for generating a "serverside export request",
  //but this was disabled once all grids were standardized to current "Download As..." functionality, restore this later as desired
  async function excelDetailsGridExport() {
    let filterAndSortSettings = {};
    filterAndSortSettings.filter = detailsGridDataState.filter;
    filterAndSortSettings.sort = detailsGridDataState.sort;

    let exportParameters = { ...detailsGridParameters };
    exportParameters.DataState = JSON.stringify(filterAndSortSettings);
    console.log(exportParameters);

    const accessToken = await passedProps.auth.getAccessToken();
    await axios
      .post(
        "api/AgingDetails/PostCurrentAgingExcelExportRequestToAzureStorage",
        exportParameters,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then(onSuccess)
      .catch(onFailure);
  }

  /////////////
  const onDetailsGridDataStateChange = (event) => {
    setDetailsGridDataState(event.dataState);

    let processedData = process(
      detailsGridData.map((item) => ({
        ...item,
        [DETAILS_SELECTED_FIELD]: selectedDetailsState[idGetter(item)],
      })),
      event.dataState
    );

    setDetailsGridResultState(processedData);
  };

  //NOTE: If the details modal grid data changes, then this is what kicks in
  useEffect(() => {
    let processedData = process(detailsGridData, detailsGridDataState);

    setDetailsGridResultState(processedData);
    setDetailsGridDataState(INITIAL_DETAILS_GRID_DATA_STATE);
  }, [detailsGridData]);

  useEffect(() => {
    let aggregates = {};
    let filterSettings = {};

    filterSettings.filter = detailsGridDataState.filter;
    let filteredDetailsGridData = process(detailsGridData, filterSettings);

    if (filteredDetailsGridData.data) {
      aggregates = aggregateBy(filteredDetailsGridData.data, [
        { aggregate: "sum", field: "adjudicatedCoPay" },
        { aggregate: "sum", field: "promiseToPay" },
        { aggregate: "sum", field: "remittancePaid" },
        { aggregate: "sum", field: "outstanding" },
      ]);
    }

    // console.log(aggregates);
    setDetailsGridAggregates(aggregates);

    setExportData(filteredDetailsGridData.data);

    if (_detailsGrid.current && _detailsGrid.current.columns)
      setExportColumns(_detailsGrid.current.columns);
  }, [detailsGridResultState]);
  /////////////
  function handleFormSubmit(e) {
    setIsLoading(true);
    setSubmittedFormData(e);
    getCurrentAging(e);
  }

  function getCurrentAging(e) {
    let accessToken = passedProps.auth.getAccessToken();

    try {
      let response = axios
        .get(`api/Aging/GetCurrentAging`, {
          params: {
            user: user,
            mmids: e.mmid,
            dateType: e.dateType,
            reportType: e.reportType,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  let CustomSetupCell = (props) => {
    let cellValue = props.dataItem[props.field];

    return (
      <td
        {...props.tdProps}
        colSpan={1}
        style={{
          color: cellValue ? "#008000" : "#ff0000",
          fontSize: "medium",
        }}
      >
        <i
          className={cellValue ? "fa fa-check-circle" : "fa fa-times-circle"}
        />
      </td>
    );
  };

  function getCurrentAgingData(e) {
    let accessToken = passedProps.auth.getAccessToken();

    try {
      let response = axios
        .get(`api/Aging/GetCurrentAging`, {
          params: {
            user: user,
            mmids: e.mmid,
            dateType: e.dateType,
            reportType: e.reportType,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  function getCurrentAgingDetails(props) {
    let accessToken = passedProps.auth.getAccessToken();
    setIsLoading(true)
    if (props.field === "TotalOutstanding") {
      setDetailsGridTitle(
        "BIN - " +
        props.dataItem["Bin"] +
        ", " +
        props.dataItem["Payer"] +
        " Total Outstanding"
      );
    } else {
      setDetailsGridTitle(
        "BIN - " +
        props.dataItem["Bin"] +
        ", " +
        props.dataItem["Payer"] +
        ", " +
        props.field +
        " Days Old"
      );
    }

    try {
      let parameters = {
        user: user,
        mmids: submittedFormData.mmid,
        outstandingType: submittedFormData.reportType,
        dateType: submittedFormData.dateType,
        agingDateRange: props.field.replace("+", ""),
        bin: props.dataItem["Bin"],
        payer: props.dataItem["Payer"],
      };

      setDetailsGridParameters(parameters);

      let response = axios
        .get("api/AgingDetails/GetByCurrentAging", {
          params: parameters,
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response) => {
          onSuccess(response);
        })
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  let MainGridMoneyCell = (props) => {
    let onCellClick = () => {
      setSelectedDetailProps(props);
      getCurrentAgingDetails(props);
    };

    let options = {
      onClick: onCellClick,
    };

    return MoneyCell(props, options);
  };

  async function aggregateMoneyFooterCellClick(aggregateField) {
    let filterSettings = {};
    filterSettings.filter = mainGridFilters;

    let processedData = process(mainGridData, filterSettings);
    let allFilteredBins = "";

    if (processedData && processedData.data) {
      processedData.data.forEach((element) => {
        allFilteredBins = allFilteredBins + element["Bin"] + ",";
      });
    } else {
      alert("No data is displayed on grid");
      return null;
    }

    if (aggregateField === "TotalOutstanding") {
      setDetailsGridTitle("Total Outstanding Aggregate");
    } else {
      setDetailsGridTitle(aggregateField + " Days Old Aggregate");
    }
    setIsLoading(true);
    //NOTE: We'll need to make an api call with the following settings
    let parameters = {
      user: user,
      mmids: submittedFormData.mmid,
      outstandingType: submittedFormData.reportType,
      dateType: submittedFormData.dateType,
      agingDateRange: aggregateField.replace("+", ""),
      filteredBins: allFilteredBins
    };

    const accessToken = await passedProps.auth.getAccessToken();

    const response = await axios.get(
      "api/AgingDetails/GetByCurrentAgingAggregates",
      {
        params: parameters,
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    setIsLoading(false);
    presentGridDetailData(response, true);
  }

  const AggregateMainGridMoneyFooterCell = (props) => {
    var options = {
      aggregateData: mainGridAggregates,
      onClick: () => aggregateMoneyFooterCellClick(props.field)
    };

    return AggregateMoneyFooterCell(props, options);
  };

  let CustomIgnoreDetailCell = (props) => {
    return (
      <td
        {...props.tdProps}
        colSpan={1}
        style={{
          color: "#015CAB",
        }}
      >
        <a
          style={{
            cursor: "pointer",
          }}
          onClick={() => ignoreDetailCellClick(props)}
        >
          Ignore
        </a>
      </td>
    );
  };

  const AggregateMoneyDetailFooterCell = (props) => {
    let displayAggregateValue = 0;
    if (detailsGridAggregates[props.field]) {
      displayAggregateValue = detailsGridAggregates[props.field].sum;
    }

    if (typeof displayAggregateValue === "number") {
      displayAggregateValue = displayAggregateValue.toFixed(2);
    }

    return <td {...props.tdProps}>{"$" + displayAggregateValue}</td>;
  };

  useEffect(() => {
    let processedData = process(
      detailsGridData.map((item) => ({
        ...item,
        [DETAILS_SELECTED_FIELD]: selectedDetailsState[idGetter(item)],
      })),
      detailsGridDataState
    );

    setDetailsGridResultState(processedData);
  }, [selectedDetailsState]);

  const onDetailSelectionChange = useCallback(
    (event) => {
      if (event.startColIndex == 0) {
        const newSelectedState = getSelectedState({
          event,
          selectedState: selectedDetailsState,
          dataItemKey: DETAILS_DATA_ITEM_KEY,
        });
        setSelectedDetailsState(newSelectedState);
      }
    },
    [selectedDetailsState]
  );

  const onDetailHeaderSelectionChange = useCallback(
    (event) => {
      const checkboxElement = event.syntheticEvent.target;
      const checked = checkboxElement.checked;
      const newSelectedState = {};
      // event.dataItems.forEach((item) => {
      //   newSelectedState[idGetter(item)] = checked;
      // });
      if (detailsGridData.length > 0) {
        detailsGridData.forEach((item) => {
          newSelectedState[idGetter(item)] = checked;
        });
      }
      setSelectedDetailsState(newSelectedState);
    },
    [detailsGridData, selectedDetailsState]
  );

  //NOTE: This is the NEW Ignore detail for the Kendo grid
  function ignoreDetailCellClick(props) {
    let dataRow = props.dataItem;

    setIgnoreMultipleSelectedClaimsInDetailModal(false);
    setSelectedNcpdpIgnoreModalText(dataRow.ncpdp);
    setSelectedRxIgnoreModalText(dataRow.rxNumber);
    setSelectedClid(dataRow.claimId);
    setIgnoreNote("");
    setSelectedIgnoreOption(null);
    setShowIgnoreModal(true);
  }

  function handleIgnoreMultipleSelectedClaims() {
    let selectedDetailsCount = getSelectedDetailsCount();
    let detailsCount  = detailsGridData.length;
    if (getSelectedDetailsCount() > 14 || selectedDetailsCount === detailsCount) {
      setShowIgnoreCount (true);
    }else{
       setShowIgnoreModal(true);

    }
    setIgnoreMultipleSelectedClaimsInDetailModal(true);
    setSelectedNcpdpIgnoreModalText("All Selected");
    setSelectedRxIgnoreModalText("All Selected");
    setSelectedIgnoreOption(null);
    setIgnoreNote("");
    // setShowIgnoreModal(true);
  }

  function getSelectedDetailsCount(){
    let detailStateKeys = Object.keys(selectedDetailsState);
    let getOnlyTrueKeys = detailStateKeys.filter(function (key) {
      return selectedDetailsState[key] === true;
    });
    return getOnlyTrueKeys.length;
  }

  function handleIgnoreCountClick(){
    setShowIgnoreCount(false);
    setShowIgnoreModal(true);
  }

  //Called when user clicks save in ignore details modal
  async function handleSaveIgnoreClick() {
    if (!selectedIgnoreOption) {
      return alert("Please Select an Ignore Code to Save!");
    }

    setShowIgnoreModal(false);

    //NOTE: If there are any values under selectedDetailState, then this variable should become false
    let isIgnoringSingleClaim = true;

    if (ignoreMultipleSelectedClaimsInDetailModal) {
      isIgnoringSingleClaim = false;
    }

    //NOTE: This should cover most cases, like when a single claim is ignored
    if (isIgnoringSingleClaim) {
      try {
        const accessToken = await passedProps.auth.getAccessToken();

        let parameters = {
          user: user,
          IgnoreId: selectedIgnoreOption.toString(),
          IgnoreNote: ignoreNote,
          ClaimIds: selectedClid.toString(),
        };
        const response = await axios.get("api/AgingDetails/IgnoreClaims", {
          params: parameters,
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        updateDetailsGridAfterIgnore(response);
      } catch (e) {
        setIgnoreError({ status: 501, Message: "" });
        setShowIgnoreModal(true);
      }
    } else {
      var detailStateKeys = Object.keys(selectedDetailsState);

      var getOnlyTrueKeys = detailStateKeys.filter(function (key) {
        return selectedDetailsState[key] === true;
      });

      //NOTE: Get all clids that are being ignored
      var strClids = "";
      getOnlyTrueKeys.forEach(function (key) {
        strClids += key + ",";
      });

      try {
        const accessToken = await passedProps.auth.getAccessToken();

        let parameters = {
          user: user,
          IgnoreId: selectedIgnoreOption.toString(),
          IgnoreNote: ignoreNote,
          ClaimIds: strClids,
        };
        const response = await axios.get("api/AgingDetails/IgnoreClaims", {
          params: parameters,
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        updateDetailsGridAfterIgnore(response);
      } catch (e) {
        setIgnoreError({ status: 501, Message: "" });
        setShowIgnoreModal(true);
      }
    }
  }

  //NOTE: I think this basically "removes a specific item on the grid visually while the ignore action is taking place"
  function updateDetailsGridAfterIgnore(response) {
    if (response.config.params.ClaimIds) {
      let ignoredClaimIds = response.config.params.ClaimIds;
      getCurrentAgingDetails(selectedDetailProps);
    }
    setIgnoreActionCompletedMessage();
  }

  function setIgnoreActionCompletedMessage() {
    setDetailError({
      status: 200,
      Message: "Selected items have been queued to be ignored!",
    });
    setTimeout(() => {
      setDetailError({});
    }, 3600);
  }

  function onSuccess(response) {
    switch (response.config.url) {
      case "api/Aging/GetCurrentAging":
        presentGridData(response.data);
        break;
      case "api/AgingDetails/GetByCurrentAging":
        presentGridDetailData(response);
        break;
      case "api/AgingDetails/PostCurrentAgingExcelExportRequestToAzureStorage":
        //console.log(response);
        setDetailError({
          status: 200,
          Message: "Excel export request has been created!",
        });
        break;
      default:
        break;
    }
    setIsLoading(false);
  }

  function onFailure(error) {
    LogError(error);

    switch (error.response.config.url || error.config.url) {
      case "api/Aging/GetCurrentAging":
        setError(error.response);
        setMainGridColumns([]);
        setMainGridData([]);
        break;
      case "api/AgingDetails/GetByCurrentAging":
        setDetailError(error.response);
        setDetailsSummaryData([]);
        setDetailsGridData([]);
        setShowModal(true);
        break;
      default:
        setError(error.response);
        setMainGridColumns([]);
        setMainGridData([]);
        break;
    }
    setIsLoading(false);
    return;
  }

  function presentGridData(data) {
    let columns = [];

    if (data.length > 0) {
      let headerProps = {
        PBID: { title: "PBID", hidden: true },
        Payer: { title: "Payer Name", selectable: false },
        Bin: { title: "BIN", selectable: false },
        TotalOutstanding: {
          title: "Total Outstanding",
          type: "money",
          showTotal: true,
          selectable: true,
        },
        Setup: { title: "Setup", type: "bool" },
      };

      Object.keys(data[0]).map((key) => {
        if (key !== "PBID") {
          columns.push({
            accessor: key,
            title: headerProps[key]
              ? headerProps[key].title
              : key.includes("+")
                ? `${key} days and over`
                : `${key} days`,
            type: headerProps[key] ? headerProps[key].type : "money",
            showTotal: headerProps[key] ? headerProps[key].showTotal : true,
            selectable: headerProps[key] ? headerProps[key].selectable : true,
          });
        }
      });
      setError({});
    } else {
      setError({ status: 201, Message: "No data found for given params" });
    }

    setMainGridData(data);
    setMainGridColumns(columns);

    let moneyColumns = [];
    columns.forEach((column) => {
      if (column.type === "money") {
        moneyColumns.push({ aggregate: "sum", field: column.accessor });
      }
    });

    setMainGridMoneyColumnAggregateSettings(moneyColumns);
  }

  function presentGridDetailData(response, isAggregate = false) {
    let detailSummaryData = [];

    if (response.data.length > 0) {
      let headerProps = {
        ignore: { title: "Actions", fixedLeft: true, selectable: true },
        ncpdp: { title: "NCPDP", fixedLeft: true },
        rxNumber: { title: "Rx #", fixedLeft: true },
        dispensedDate: {
          title: "Dispensed Date",
          fixedLeft: true,
          type: "date",
        },
        transactionDate: { title: "Transaction Date", type: "date" },
        bin: { title: "BIN" },
        pcn: { title: "PCN" },
        claimNumber: { title: "Claim #" },
        cardholderId: { title: "Cardholder ID" },
        adjudicatedCoPay: {
          title: "Adjudicated Copay",
          type: "money",
          showTotal: true,
        },
        promiseToPay: {
          title: "Promise to Pay",
          type: "money",
          showTotal: true,
        },
        remittancePaid: {
          title: "Remittance Paid",
          type: "money",
          showTotal: true,
        },
        outstanding: { title: "Outstanding", type: "money", showTotal: true },
      };

      let totalAdjudicated = 0;
      let totalPaid = 0;
      for (let i = 0; i < response.data.length; i++) {
        totalAdjudicated += response.data[i].promiseToPay;
        totalPaid += response.data[i].remittancePaid;
      }

      if (isAggregate) {
        detailSummaryData.push({
          ClaimCount: response.data.length,
          Adjudicated: totalAdjudicated,
          Paid: totalPaid,
          Outstanding: totalAdjudicated - totalPaid
        });
      } else {
        detailSummaryData.push({
          Payer: response.config.params.payer,
          ClaimCount: response.data.length,
          Adjudicated: totalAdjudicated,
          Paid: totalPaid,
          Outstanding: totalAdjudicated - totalPaid
        });
      }

      setDetailError({});
    } else {
      setDetailError({
        status: 201,
        Message: "No data found for given params",
      });
    }

    setDetailsSummaryData(detailSummaryData);

    let detailsDataWithCleanedDates = response.data.map((t) => {
      return {
        ...t,
        dispensedDate: IsNullOrWhitespace(t.dispensedDate)
          ? ""
          : new Date(Date.parse(t.dispensedDate)),
        transactionDate: IsNullOrWhitespace(t.transactionDate)
          ? ""
          : new Date(Date.parse(t.transactionDate)),
      };
    });
    setDetailsGridData(detailsDataWithCleanedDates);
    setShowModal(true);
  }

  function CreateGridColumn(column) {
    let columnWidth = DefaultColumnWidth();

    let filterType = "text";
    if (column.type === "money") {
      filterType = "numeric";
    } else if (column.type === "bool") {
      filterType = "boolean";
    }

    if (column.accessor === "Setup") {
      return (
        <GridColumn
          key={column.accessor}
          filter={filterType}
          field={column.accessor}
          title={column.title}
          width={columnWidth}
          cells={{
            data: CustomSetupCell,
          }}
        />
      );
    } else if (column.type === "money") {
      return (
        <GridColumn
          key={column.accessor}
          filter={filterType}
          field={column.accessor}
          title={column.title}
          width={columnWidth}
          cells={{
            data: MainGridMoneyCell,
            footerCell: AggregateMainGridMoneyFooterCell,
          }}
          footerCell={AggregateMainGridMoneyFooterCell}
        />
      );
    } else {
      return (
        <GridColumn
          key={column.accessor + "Column"}
          filter={filterType}
          field={column.accessor}
          title={column.title}
          width={columnWidth}
        />
      );
    }
  }

  return (
    <div className="feature-tab" id="current-aging">
      <LoadingSpinner
        isDataLoading={isLoading}
        controlsName={"agingCurrentAging"}
      />
      <ErrorHandler
        error={error}
        onClose={() => {
          setError({});
        }}
      />
      <Accordion defaultExpanded label="Search & Filter">
        <Row>
          <Col className="higher-zindex-filters">
            <CurrentAgingForm handleFormSubmit={handleFormSubmit} />
          </Col>
        </Row>
      </Accordion>
      <div ref={_container}>
        <Row>
          <Col className="tight-grid multi-line-filter">
            <SharedMainGrid
              data={mainGridData}
              aggregateColumnSettings={mainGridMoneyColumnAggregateSettings}
              sendAggregatesParentCallback={sendAggregatesToParent}
              exportFileNamePrefix={"CurrentAging"}
            >
              {mainGridColumns.length > 0
                ? mainGridColumns.map((singleColumn) =>
                  CreateGridColumn(singleColumn)
                )
                : null}
            </SharedMainGrid>
          </Col>
        </Row>
      </div>
      <IgnoreModal 
          title="Ignore Count" 
          show={showIgnoreCount}
          handleClose={()=>{
            setShowIgnoreCount(false);
          }}>
             <Container>
          <Row>
            You have selected to Ignore {getSelectedDetailsCount()} number of claims. Do you wish to continue?
          </Row>
        
          <br />
          <Row>
            <Col>
              <Button
                variant="secondary"
                onClick={() => setShowIgnoreCount(false)}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                className="primary-button"
                onClick={handleIgnoreCountClick}
              >
                Ok
              </Button>
            </Col>
          </Row>
        </Container>
        </IgnoreModal>
      <DetailsModal
        title="Payer Summary"
        show={showModal}
        handleClose={() => {
          setIsLoading(true);
          getCurrentAging(submittedFormData);
          return (
            setShowModal(false),
            setSelectedDetailsState({}),
            _container.current.focus()
          );
        }}
      >
        <ErrorHandler
          error={detailError}
          onClose={() => {
            setDetailError({});
          }}
        />
        <div>
          <Row>
            <Col className="tight-grid multi-line-filter">
              <Grid
                style={{
                  maxHeight: "10em",
                }}
                data={detailsSummaryData}
              >
                {detailsSummaryData[0] && detailsSummaryData[0].Payer ? (
                  <GridColumn
                    key={"Payer"}
                    field={"Payer"}
                    title={"Payer"}
                    width={DefaultColumnWidth()}
                  />
                ) : null}
                <GridColumn
                  key={"ClaimCount"}
                  field={"ClaimCount"}
                  title={"Claim Count"}
                  width={DefaultColumnWidth()}
                />
                <GridColumn
                  key={"Adjudicated"}
                  field={"Adjudicated"}
                  title={"Adjudicated"}
                  width={DefaultColumnWidth()}
                  cells={{ data: MoneyCell }}
                />
                <GridColumn
                  key={"Paid"}
                  field={"Paid"}
                  title={"Paid"}
                  width={DefaultColumnWidth()}
                  cells={{ data: MoneyCell }}
                />
                <GridColumn
                  key={"Outstanding"}
                  field={"Outstanding"}
                  title={"Outstanding"}
                  width={DefaultColumnWidth()}
                  cells={{ data: MoneyCell }}
                />
              </Grid>
            </Col>
          </Row>
        </div>
        <br />
        <h2>Aging Detail</h2>

        <h4>{detailsGridTitle}</h4>
        <div>
          {/* 
          NOTE: Using the ZIndexContext.Provider here, we fix the z-index used for "layering" the Kendo grid
          in relation to the Bootstrap modal. This allows the filtering dropdowns to display and work as intended.
          */}
          <ZIndexContext.Provider value={10003}>
            <ExcelExport ref={_detailsExport}>
              <GridExport
                exportData={exportData}
                exportDataColumns={exportColumns}
                exportFileNamePrefix={"CurrentAgingDetails"}
              />
              <Row>
                <Col className="tight-grid multi-line-filter">
                  <Grid
                    ref={_detailsGrid}
                    style={{
                      maxHeight: "30em",
                    }}
                    data={detailsGridResultState}
                    dataItemKey={DETAILS_DATA_ITEM_KEY}
                    selectedField={DETAILS_SELECTED_FIELD}
                    selectable={{
                      enabled: true,
                      drag: false,
                      cell: false,
                      mode: "multiple",
                    }}
                    onSelectionChange={onDetailSelectionChange}
                    onHeaderSelectionChange={onDetailHeaderSelectionChange}
                    onDataStateChange={onDetailsGridDataStateChange}
                    sortable={true}
                    {...detailsGridDataState}
                    filterable={true}
                    pageable={true}
                  >
                    <GridToolbar>
                      <button
                        title="Ignore Selected Claims"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={handleIgnoreMultipleSelectedClaims}
                      >
                        Ignore Selected Claims
                      </button>
                    </GridToolbar>
                    <GridColumn
                      field={DETAILS_SELECTED_FIELD}
                      width="50px"
                      filterable={false}
                      headerSelectionValue={
                        detailsGridData.findIndex(
                          (item) => !selectedDetailsState[idGetter(item)]
                        ) === -1
                      }
                    />
                    <GridColumn
                      key={""}
                      field={""}
                      title={"Ignore"}
                      width={"100em"}
                      cells={{
                        data: CustomIgnoreDetailCell,
                      }}
                    />
                    <GridColumn
                      key={"ncpdp"}
                      field={"ncpdp"}
                      title={"NCPDP"}
                      width={DefaultBooleanOrShortWidthColumnWidth()}
                    />
                    <GridColumn
                      key={"rxNumber"}
                      field={"rxNumber"}
                      title={"Rx #"}
                      filter={"text"}
                      width={DefaultColumnWidth()}
                    />
                    <GridColumn
                      key={"dispensedDate"}
                      field={"dispensedDate"}
                      title={"Dispensed Date"}
                      filter={"date"}
                      format="{0:d}"
                      width={DefaultDateColumnWidth()}
                    />
                    <GridColumn
                      key={"transactionDate"}
                      field={"transactionDate"}
                      title={"Transaction Date"}
                      filter={"date"}
                      format="{0:d}"
                      width={DefaultDateColumnWidth()}
                    />
                    <GridColumn
                      key={"bin"}
                      field={"bin"}
                      title={"BIN"}
                      width={DefaultColumnWidth()}
                    />
                    <GridColumn
                      key={"pcn"}
                      field={"pcn"}
                      title={"PCN"}
                      width={DefaultColumnWidth()}
                    />
                    <GridColumn
                      key={"claimNumber"}
                      field={"claimNumber"}
                      title={"Claim #"}
                      width={DefaultColumnWidth()}
                    />
                    <GridColumn
                      key={"cardHolderId"}
                      field={"cardHolderId"}
                      title={"Cardholder ID"}
                      width={DefaultColumnWidth()}
                    />
                    <GridColumn
                      key={"adjudicatedCoPay"}
                      field={"adjudicatedCoPay"}
                      title={"Copay"}
                      filter={"numeric"}
                      width={DefaultColumnWidth()}
                      cells={{
                        data: MoneyCell,
                        footerCell: AggregateMoneyDetailFooterCell,
                      }}
                      footerCell={AggregateMoneyDetailFooterCell}
                    />
                    <GridColumn
                      key={"promiseToPay"}
                      field={"promiseToPay"}
                      title={"Promise to Pay"}
                      filter={"numeric"}
                      width={DefaultColumnWidth()}
                      cells={{
                        data: MoneyCell,
                        footerCell: AggregateMoneyDetailFooterCell,
                      }}
                      footerCell={AggregateMoneyDetailFooterCell}
                    />
                    <GridColumn
                      key={"remittancePaid"}
                      field={"remittancePaid"}
                      title={"Paid"}
                      filter={"numeric"}
                      width={DefaultColumnWidth()}
                      cells={{
                        data: MoneyCell,
                        footerCell: AggregateMoneyDetailFooterCell,
                      }}
                      footerCell={AggregateMoneyDetailFooterCell}
                    />
                    <GridColumn
                      key={"outstanding"}
                      field={"outstanding"}
                      title={"Outstanding"}
                      filter={"numeric"}
                      width={DefaultColumnWidth()}
                      cells={{
                        data: MoneyCell,
                        footerCell: AggregateMoneyDetailFooterCell,
                      }}
                      footerCell={AggregateMoneyDetailFooterCell}
                    />
                  </Grid>
                </Col>
              </Row>
            </ExcelExport>
          </ZIndexContext.Provider>
        </div>
       
      </DetailsModal>
      <IgnoreModal
        title="Ignore Details"
        show={showIgnoreModal}
        handleClose={() => {
          return (
            setShowIgnoreModal(false),
            setIgnoreMultipleSelectedClaimsInDetailModal(false),
            setDetailsGridDataState({}),
            setDetailsGridData([])
          );
        }}
      >
        <ErrorHandler
          error={IgnoreError}
          onClose={() => {
            setIgnoreError({});
          }}
        />
        <Container>
          <Row>
            <Col> NCPDP </Col>
            <Col> Rx #</Col>
          </Row>
          <Row>
            <Col>{selectedNcpdpIgnoreModalText}</Col>
            <Col>{selectedRxIgnoreModalText}</Col>
          </Row>
          <br />
          <Row>
            <Col> Ignore Code </Col>
          </Row>
          <Row md={1}>
            <Col>
              <SingleSelectDropdown
                options={IgnoreOptions}
                highlightSelection={true}
                onChange={(selectedIgnoreOption) => {
                  setSelectedIgnoreOption(selectedIgnoreOption);
                }}
              />
            </Col>
          </Row>
          <br />
          <Row md={1}>
            <Col>
              <Form.Group>
                <Form.Label> Comments </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="5"
                  placeholder="Type your message here..."
                  value={ignoreNote}
                  onChange={(event) => {
                    setIgnoreNote(event.target.value);
                  }}
                >
                  {" "}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Button
                variant="secondary"
                onClick={() => setShowIgnoreModal(false)}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                className="primary-button"
                onClick={handleSaveIgnoreClick}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Container>
      </IgnoreModal>
    </div>
  );
}
