import React, { useState, useEffect } from "react";

import axios from "axios";

import { RequestExports } from "./RequestExports";
import { ExportBatchHistory } from "./ExportBatchHistory";
import TabBasedFeature from "../../layout/TabBasedFeature";
import { UserContext } from "../../context/UserContext";
import { OrganizationTypes } from "../../../constants/OrganizationTypes";
import NoAccess from "../../auth/NoAccess";
import ParentCompanySelectionForGroupUsers from "./ParentCompanySelectionForGroupUsers";

export default function RemittanceExporting(props) {
  const { passedProps } = props;
  let userContext = React.useContext(UserContext);

  const [parentCompanySelectedByGroupUser, setParentCompanySelectedByGroupUser] = useState(null);
  const [membersSelectedByGroupUser, setMembersSelectedByGroupUser] = useState(null);
  const [payersSelectedByGroupUser, setPayersSelectedByGroupUser] = useState(null);
  const [groupUserHasDeterminedValues, setGroupUserHasDeterminedValues] = useState(false);

  const sendParentCompanyFromGroupUser = (parentCompanyId) =>{
    setParentCompanySelectedByGroupUser(parentCompanyId);
  };

  const sendMembersFromGroupUser = (members) =>{
    setMembersSelectedByGroupUser(members);
  };

  const sendPayersFromGroupUser = (payers) =>{
    setPayersSelectedByGroupUser(payers);
  };

  const resetValuesSelectedByGroupUser = () =>{
    setParentCompanySelectedByGroupUser(null);
    setMembersSelectedByGroupUser(null);
    setGroupUserHasDeterminedValues(false);
  };

  useEffect(() => {
    resetValuesSelectedByGroupUser();
  }, [userContext.currentOrganization.value]);

  /*
  NOTE: Okay so from here, we can use spread operator to continue passing the existing passedProps, and then add some additional values on top of it
  From here, those props get passed to the TabBasedFeature, and then from there it gets passed to the actual features themselves.
  This is how we can determine the "user determined RPID" and a "user determined set of members".
  */

  let customPassedProps = {...passedProps,
    parentCompanySetByGroupUser: parentCompanySelectedByGroupUser,
    membersSetByGroupUser: membersSelectedByGroupUser,
    payersSetByGroupUser: payersSelectedByGroupUser,
    resetValuesSelectedByGroupUserCallback: () => resetValuesSelectedByGroupUser()
  };

  useEffect(() => {
    if(parentCompanySelectedByGroupUser && membersSelectedByGroupUser.length > 0){
      setGroupUserHasDeterminedValues(true);
    }
  },[parentCompanySelectedByGroupUser, membersSelectedByGroupUser]);

  const [
    isCurrentOrganizationAParentCompany,
    setIsCurrentOrganizationAParentCompany,
  ] = useState(false);
  const [hasRequiredExportAttributesSet, setHasRequiredExportAttributesSet] =
    useState(false);

  const [currentOrganizationTrigger, setCurrentOrganizationTrigger] =
    useState(false);

  useEffect(() => {
    if (
      userContext !== null &&
      userContext.currentOrganization !== null &&
      userContext.currentOrganization.type !== null
    ) {
      let isParentCompany =
        userContext.currentOrganization.type ===
        OrganizationTypes.ParentCompany;
      setIsCurrentOrganizationAParentCompany(isParentCompany);

      if (isParentCompany) GetParentCompanyAttributes();
    }
  }, [currentOrganizationTrigger]);

  function SetCurrentOrganizationTrigger() {
    if (!userContext.currentOrganization) return;

    if (!userContext.currentOrganization.value) return;

    if (currentOrganizationTrigger === userContext.currentOrganization.value)
      return;

    setCurrentOrganizationTrigger(userContext.currentOrganization.value);
  }

  function onUserLocationPayersResponseSuccess(response) {
    let data = response.data;

    // add a list of target arrtibutes to check for
    let targetAttributes = ["835 Automatic Exports", "835 Export Options"];

    // check if the response has any of the target attributes
    let hasRequiredAttributes = targetAttributes.some((x) =>
      data.find(
        (y) => y.attributeName.trim().toLowerCase() === x.trim().toLowerCase()
      )
    );

    setHasRequiredExportAttributesSet(hasRequiredAttributes);
  }

  SetCurrentOrganizationTrigger();

  function onFailure(error) {
    alert("onFailure error " + error);
  }

  async function GetParentCompanyAttributes() {
    try {
      let accessToken = passedProps.auth.getAccessToken();

      let targetAttributes = "835 Automatic Exports,835 Export Options";

      let parameters = {
        dataSourceType: userContext.currentOrganization.type,
        dataSourceId: userContext.currentOrganization.value,
        attributes: targetAttributes,
      };
      await axios
        .get("api/Members/GetMemberParentCompanyAttributes", {
          params: parameters,
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onUserLocationPayersResponseSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  let tabs = [
    {
      key: "requestExports",
      title: "Request Exports",
      Component: RequestExports,
    },
    {
      key: "exportBatchHistory",
      title: "Export Batch History",
      Component: ExportBatchHistory,
    },
  ];

  if (!isCurrentOrganizationAParentCompany && !groupUserHasDeterminedValues) {
    return (
      (
        <ParentCompanySelectionForGroupUsers 
        setParentCompanyCallback = {sendParentCompanyFromGroupUser}
        setMembersCallback = {sendMembersFromGroupUser}
        setPayersCallback = {sendPayersFromGroupUser}
        passedProps = {passedProps}
        />
      )
    );
  } else if (!hasRequiredExportAttributesSet && !groupUserHasDeterminedValues) {
    return (
      <NoAccess
        displayMessage={
          "You have selected a feature that has not been enabled for your account. Please contact Net-Rx at 866-336-3879 for more information about this feature."
        }
      ></NoAccess>
    );
  } else {
    return (
      <TabBasedFeature
        tabs={tabs}
        defaultActiveKey="requestExports"
        passedProps={customPassedProps}
      />
    );
  }
}
