import { useContext, useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { UserContext } from "../context/UserContext";

import { IsUnrestrictedEraRoute } from "../../constants/EraPaths";

export function useEraLocations() {
  const userContext = useContext(UserContext);
  const { oktaAuth } = useOktaAuth();

  const [eraOnly, setEraOnly] = useState(false);

  //if EraOnly is true, then the user can only Access Allowed ERA Routes

  let { currentOrganization } = userContext;

  if (!currentOrganization) {
    currentOrganization = {
      value: null,
      type: null,
    };
  }

  function onFailure(error) {
    //todo: write a log
  }

  function onUserLocationPayersResponseSuccess(response) {
    let data = response.data;

    //if there is no data, then we set eraOnly to false and return
    if (!data || data.length === 0) {
      if (eraOnly !== false) {
        setEraOnly(false);
      }
      return;
    }

    //check if there is even 1 record in the response where the attributeValuie is not ERA

    let nonEraLocationExists = data.some(
      (x) => x.attributeValue.trim().toUpperCase() !== "ERA"
    );

    if (nonEraLocationExists) {
      if (eraOnly !== false) {
        setEraOnly(false);
      }
    } else {
      if (eraOnly !== true) {
        setEraOnly(true);
      }
    }
  }

  function IsAllowedRoute(route) {
    if (eraOnly === false) return true;

    return IsUnrestrictedEraRoute(route);

    // let allowedEraRoutes = [
    //   "/",
    //   "/RemitLookUp",
    //   "/MissingRemittances",
    //   "/835RemittanceExporting",
    //   "/ManualRemittance",
    //   //NOTE: This is for handling any "Admin only" routes, we don't want to prevent usage for internal UCT users
    //   "/AdminExportBatchQueueMaintenance",
    // ];
    // //if the route is in the allowedEraRoutes array, then we return true else we return false

    // return allowedEraRoutes.includes(route);
  }

  async function GetProcessTypeAttributes() {
    try {
      let accessToken = oktaAuth.getAccessToken();

      let parameters = {
        dataSourceType: currentOrganization.type,
        dataSourceId: currentOrganization.value,
        attributes: "Process Type",
      };

      await axios
        .get("api/Members/GetMemberParentCompanyAttributes", {
          params: parameters,
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onUserLocationPayersResponseSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  useEffect(() => {
    //if the currentOrganization.value is null, then we do not proceed
    if (!currentOrganization.value) {
      if (eraOnly !== false) setEraOnly(false);

      return;
    }

    GetProcessTypeAttributes();

    //console.log("useEraLocations useEffect", currentOrganization);
  }, [currentOrganization]);

  return { eraOnly, IsAllowedRoute };
}
